import { useCallback } from "react";
import { djangoApi } from "utils/djangoApi";
import { AxiosError } from "axios";
import flatten from "flat";
import { useAuth } from "components/auth/useAuth";
import { useErrorModal } from "components/modal/useErrorModal";

// Sample error response from DRF
// {
//   "contact": { "email": ["Enter a valid email address."] },
//   "infrastructure_type": ["\"transpora\" is not a valid choice."]
// }
//
// Transforms it to this
// [
//   "\"contact.email\":\"Enter a valid email address.\"",
//   "\"infrastructure_type\":\"\"transpora\" is not a valid choice.\""
// ]
const getNestedStrings = (obj: any): string[] => {
  if (typeof obj !== "object" || !obj) return [];
  return JSON.stringify(flatten(obj))
    .replace(/[{}[\]\\]/g, "") // Strips out unwanted characters
    .replace(/\.\d/g, "") // Removes .0 (array index) from the keys
    .split(",");
};

export const useDjangoApi = () => {
  const { isAuthenticated, logout } = useAuth();
  const { setErrorModal } = useErrorModal();

  const handleError = useCallback(
    (error: unknown) => {
      if (!(error instanceof AxiosError)) throw error;
      if (typeof error.response?.status !== "number") throw error;

      // Perform common error handling
      const isAuthError = [401, 403].includes(error.response.status);
      if (isAuthenticated && isAuthError) {
        setErrorModal({
          title: "Request failed",
          content: "Your session was expired. Please log in again.",
        });
        logout();
        return;
      }

      // In case of 400, display error messages that are automatically set by Django rest framework
      if (error.response.status === 400) {
        const respData = error.response.data ?? [];
        setErrorModal({
          title: "Request failed",
          content:
            getNestedStrings(respData)?.[0] ?? // try get first error message in response if available
            "Something went wrong. Please try again.",
        });
      } else {
        // Other cases e.g. >= 500
        setErrorModal({
          title: "Request failed",
          content: `${error.response.status} ${error.response.statusText}`,
        });
      }

      throw error;
    },
    [isAuthenticated, logout, setErrorModal]
  );

  const postDjangoApi = async (...args: Parameters<typeof djangoApi.post>) => {
    try {
      const resp = await djangoApi.post(...args);
      return resp?.data ?? null;
    } catch (error: unknown) {
      handleError(error);
    }
  };

  const putDjangoApi = async (...args: Parameters<typeof djangoApi.put>) => {
    try {
      const resp = await djangoApi.put(...args);
      return resp?.data ?? null;
    } catch (error: unknown) {
      handleError(error);
    }
  };

  const deleteDjangoApi = async (
    ...args: Parameters<typeof djangoApi.delete>
  ) => {
    try {
      await djangoApi.delete(...args); // don't return anything, response should be 204 No Content
    } catch (error: unknown) {
      handleError(error);
    }
  };

  // useQuery doesn't fit the use case of search because we want to run search on button click
  // rather than on component mount. Also it needs 400 error handling.
  const search = useCallback(
    async (...args: Parameters<typeof djangoApi.post>) => {
      try {
        const resp = await djangoApi.post(...args);
        return resp?.data ?? null;
      } catch (error: unknown) {
        handleError(error);
      }
    },
    [handleError]
  );

  // Use useDjangoQuery for GET request
  return {
    postDjangoApi,
    putDjangoApi,
    deleteDjangoApi,
    search,
  };
};
