import { useEffect, useMemo } from "react";
import { Routes, Route } from "react-router-dom";
import { Path } from "utils/constants/paths";
import { useMatchPath } from "utils/path";
import { SearchPanels } from "./Search/SearchPanels";
import { LayersPanel } from "./Layers/LayersPanel";
import { MyOrganisationPanel } from "./MyOrganisation/MyOrganisationPanel";
import { CreateProjectPanel } from "./CreateProject/CreateProjectPanel";
import { EditProjectPanel } from "./CreateProject/EditProjectPanel";
import { ProjectsPanel } from "./Project/ProjectsPanel";
import { CreateStagePanel } from "./CreateProject/CreateStagePanel";
import { EditStagePanel } from "./CreateProject/EditStagePanel";
import { PanelWrapper } from "./PanelWrapper";
import { PanelBody } from "./PanelBody";
import { ProjectValidator } from "./Project/ProjectValidator";
import { StageValidator } from "./Project/StageValidator";
import {
  RequireAddProject,
  RequireEditProject,
} from "components/permission/RequirePermission";
import { usePanels, PanelID } from "components/panel/PanelsProvider";
import { useSearchedProjects } from "components/project/searched-projects/SearchedProjectsContext";
import "./Panel.scss";
import { DashboardPanel } from "./Dashboard/DashboardPanel";
import { ProjectPanel } from "./Project/ProjectPanel";

export const Panel = () => {
  const { isClosed } = usePanels();
  const { resetFormSearch } = useSearchedProjects();

  const isOnIndexPath = useMatchPath(Path.Index);
  const isOnSearchPath = useMatchPath(Path.Search);

  const projectPanel: React.ReactNode = useMemo(() => <ProjectPanel />, []);

  useEffect(() => {
    // If we are no longer on the search path and the search panels have been
    // closed, reset the form search, but keep the point search as it can be
    // used for "Projects in area".
    // This useEffect cannot be placed in the SearchPanels component as it is
    // not mounted at that point:
    if (isClosed(PanelID.SearchForm) && !isOnSearchPath) {
      resetFormSearch();
    }
  }, [isOnSearchPath, isClosed, resetFormSearch]);

  return (
    <div className="shadow d-flex" style={{ zIndex: 1000 }}>
      <Routes>
        <Route path="/my-organisation" element={<MyOrganisationPanel />} />
        <Route path="/project/*" element={<MyOrganisationPanel />} />
      </Routes>
      <Routes>
        <Route path="/dashboard" element={<DashboardPanel />} />
        <Route
          path="/dashboard/watchlist/:watchlistId"
          element={<DashboardPanel />}
        />
        <Route path="search" element={<SearchPanels />} />
        {/* Ensure that spawned panels during a form search are loaded through <SearchPanels /> so that these can stay open */}
        <Route path="search/:lng/:lat" element={<SearchPanels />} />
        <Route path="project/*" element={<SearchPanels />} />
      </Routes>
      <Routes>
        {/* The Projects in area panel isn't used during a form search, so it doesn't go through <SearchPanels /> */}
        <Route path="projects/:lng/:lat" element={<ProjectsPanel />} />
      </Routes>
      <PanelWrapper isOpen={!isOnIndexPath}>
        <Routes>
          <Route path="/">
            <Route path="project">
              <Route
                path="add"
                element={
                  <RequireAddProject>
                    <CreateProjectPanel />
                  </RequireAddProject>
                }
              />
              <Route path=":projectId" element={<ProjectValidator />}>
                <Route path="" element={projectPanel} />
                <Route
                  path="edit"
                  element={
                    <RequireEditProject>
                      <EditProjectPanel />
                    </RequireEditProject>
                  }
                />
                <Route path="stages">
                  <Route path="" element={projectPanel} />
                  <Route
                    path="add"
                    element={
                      <RequireEditProject>
                        <CreateStagePanel />
                      </RequireEditProject>
                    }
                  />
                  <Route path=":stageId" element={<StageValidator />}>
                    <Route path="" element={projectPanel} />
                    <Route
                      path="edit"
                      element={
                        <RequireEditProject>
                          <EditStagePanel />
                        </RequireEditProject>
                      }
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="layers" element={<LayersPanel />} />
            {/* Show empty panel so it renders closing animation */}
            <Route index element={<PanelBody>{null}</PanelBody>} />
          </Route>
        </Routes>
      </PanelWrapper>
    </div>
  );
};
