import { createContext, useState, useContext, useMemo, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { SearchResults } from "pages/root/Panel/Search/SearchForm";
import { useDjangoApi } from "components/fetch/useDjangoApi";
import { MyOrganisationFilterValue } from "pages/root/Panel/MyOrganisation/MyOrganisationFilterContext";
import { useMyOrganisation } from "components/fetch/useMyOrganisation";
import { projectsPerPage } from "components/project/pagination/PaginationNavigation";
import { useMatchPath } from "utils/path";
import { Path } from "utils/constants/paths";

export enum MyOrganisationsProjectsStatus {
  Disabled = "disabled", // not on the correct path
  Idle = "idle", // on the correct path and doing nothing
  Requested = "requested", // provider should fetch data using current parameters
  InProgress = "inProgress", // waiting on a response
}

interface MyOrganisationsProjectsContextInterface {
  status: MyOrganisationsProjectsStatus;
  projects?: SearchResults;
  page: number;
  setFilter: (filter: MyOrganisationFilterValue) => void;
  refetch: () => void;
  reset: () => void;
  isLoading: boolean;
}

export const MyOrganisationsProjectsProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const { search } = useDjangoApi();
  const organisation = useMyOrganisation();
  const matchedPath = useMatchPath(Path.MyOrganisation);
  const [searchParams] = useSearchParams();

  const [filter, setFilter] = useState<MyOrganisationFilterValue | null>(null);
  const [results, setResults] = useState<{ [key: number]: SearchResults }>({});
  const [status, setStatus] = useState<MyOrganisationsProjectsStatus>(
    matchedPath
      ? MyOrganisationsProjectsStatus.Requested
      : MyOrganisationsProjectsStatus.Disabled
  );

  const page = useMemo(
    () => parseInt(searchParams.get("page") ?? "1", 10),
    [searchParams]
  );

  const params = useMemo(() => {
    const p = new FormData();
    if (organisation?.name) {
      p.append("organisation", organisation.name);
    }
    p.append("state", "PLANNED");
    p.append("state", "IN_PROGRESS");
    p.append("state", "DEFERRED");
    p.append("state", "STALLED");
    p.append("order_by", "-last_updated");

    if (filter) {
      Object.entries(filter).forEach(([key, value]) => {
        // MultipleCombobox values are stored in an array.
        // Append them separately to the params.
        if (Array.isArray(value)) {
          value.forEach((item: any) => {
            p.append(key, item ?? "");
          });
        } else {
          p.append(key, value);
        }
      });
    }

    return p;
  }, [filter, organisation]);

  const handleFilterChange = (filter: MyOrganisationFilterValue) => {
    setFilter(filter);
    setResults({});
    if (status === MyOrganisationsProjectsStatus.Idle) {
      setStatus(MyOrganisationsProjectsStatus.Requested);
    }
  };

  const refetch = () => {
    setResults({});
    setStatus(MyOrganisationsProjectsStatus.Requested);
  };

  const reset = () => {
    setFilter(null);
    refetch();
  };

  useEffect(() => {
    if (
      organisation &&
      params &&
      matchedPath &&
      status === MyOrganisationsProjectsStatus.Requested
    ) {
      setStatus(MyOrganisationsProjectsStatus.InProgress);
      search(
        `/api/v2/projects/search/?page_size=${projectsPerPage}&page=${page}`,
        params
      )
        .then((res: SearchResults) => {
          setResults((results) => ({ ...results, [page]: res }));
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setStatus(MyOrganisationsProjectsStatus.Idle);
        });
    }
  }, [page, params, matchedPath, organisation, status, search]);

  useEffect(() => {
    // enable on path change
    if (matchedPath && status === MyOrganisationsProjectsStatus.Disabled) {
      setStatus(MyOrganisationsProjectsStatus.Idle);
    }
  }, [matchedPath, status]);

  useEffect(() => {
    // load data on page change
    if (status === MyOrganisationsProjectsStatus.Idle && !results?.[page]) {
      setStatus(MyOrganisationsProjectsStatus.Requested);
    }
  }, [status, results, page]);

  const value = {
    status,
    projects: results?.[page],
    page,
    setFilter: handleFilterChange,
    refetch,
    reset,
    isLoading: status === MyOrganisationsProjectsStatus.InProgress,
  };

  return (
    <MyOrganisationsProjectsContext.Provider value={value}>
      {children}
    </MyOrganisationsProjectsContext.Provider>
  );
};

export const MyOrganisationsProjectsContext =
  createContext<MyOrganisationsProjectsContextInterface>(null!);

export const useMyOrganisationsProjects = () => {
  return useContext(MyOrganisationsProjectsContext);
};
